.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed #ccc;
  border-radius: 8px;
  font-family: sans-serif;
  padding: 1em;
  background-color: #f6f6f6;
}

.wrapperColorPicker {
  display: flex;
  align-items: flex-start;
  margin: 0 0 1.5em 2em;
}

.colorPicker {
  display: flex;
  align-items: center;

  .colorPickerBox {
    width: 25px;
    height: 25px;
    margin-top: 13px;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  input[type="color"] {
    visibility: hidden;
    border: none;
    width: 1.3em;
    height: 1.3em;
    margin-top: 1.3em;
  }
}
.colorHexa {
  label {
    max-width: 85%;
    font-size: 0.8em;
  }
  label ~ p {
    margin: 1em 0;
  }
}

.wrapperChip {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0.5em 0.5em 0;
}

