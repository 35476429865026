.navigationButton {
  transition: background-color 1s ease-in;
}

.wrapperChip {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0.5em 0.5em 0;
  max-width: 17rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bold > * {
  font-weight: bold !important;
}
