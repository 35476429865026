.iae_separation_cell_light {
  border-left: 1px solid #d9d9d6;
  border-right: 1px solid #d9d9d6;
  background-color: #fafafa;
/*  border-bottom: none !important;*/
}

.tab_cell_border_top_light {
  border-top: 1px solid #d9d9d6;
}

.tab_cell_border_left_light {
  border-left: 1px solid #d9d9d6;
}

.tab_cell_border_right_light {
  border-right: 1px solid #d9d9d6;
}

.tab_cell_full_borders_light {
  border: 1px solid #d9d9d6 !important;
}

.tab_cell_border_bottom_none {
  border-bottom: none;
}

.iae_separation_cell_dark {
  border-left: 1px solid rgb(81 81 81);
  border-right: 1px solid rgb(81 81 81);
  background-color: #424242;
 /* border-bottom: none !important;*/
}

.tab_cell_border_top_dark {
  border-top: 1px solid rgb(81 81 81);
}

.tab_cell_border_left_dark {
  border-left: 1px solid rgb(81 81 81);
}

.tab_cell_border_right_dark {
  border-right: 1px solid rgb(81 81 81);
}

.tab_cell_full_borders_dark {
  border: 1px solid rgb(81 81 81) !important;
}

.tab_cell_no_borders {
  border: none !important;
}

.bold_content {
  font-weight: bold !important;
}

.blank_background_light {
  background-color: #fafafa;
}

.blank_background_dark {
  background-color: #424242;
}

.audit_certification {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.audit_certification_title {
  width: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: underline;
}
.audit_certification_result {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 15px 50px;
}
.background_valid {
  background-color: #8fbc8f;
}

.background_invalid {
  background-color: #cd5c5c;
}

.menuSousRubrique {
    min-height: 40px !important;
    .sousMenuSousRubrique {
        margin: 0 0.5rem 0 0;
        padding: 6px !important;
        text-transform: none;
        line-height: 1.1em;
        max-height: 40px;
    }
 & .PrivateTabIndicator-colorSecondary-22 {
     display: none;
 }
}

.MuiTableCell-root {
    padding: 1rem;
}
