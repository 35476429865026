.dropzone {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  min-height: 50px;
  border-radius: 4px;
  border: 1px dashed #ccc;
  padding: 0.3em;
}

.chip {
  cursor: grabbing !important;
  margin: 0.3em;
}
