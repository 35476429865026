
  .sidebar {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .quicksearch,
    .advancedSearch {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
.chevronIcon {
  font-size: 0.7em;
}