/**
  * Setting height 100% to root element so app is fullpage
  */
html,
body,
#root {
    min-height: 100vh;
}

body,
#root {
    margin: 0;
    font-family: roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* min viewport width */
    min-width: 911px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1em;
    overflow-y: auto;
}

.paper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

/*
 * CSS fix for react-redux-toastr on close button color
 */
.toastr.animated.rrt-error .rrt-right-container .close-toastr.toastr-control {
    color: white !important;
}

/**
 * CSS global utilities classes
 */

/* Margins */
.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.ml-02,
.mx-02 {
    margin-left: 0.2rem !important;
}

.ml-05,
.mx-05 {
    margin-left: 0.5rem !important;
}

.mr-05,
.mx-05 {
    margin-right: 0.5rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 1rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 1rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 2rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 2rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 3rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 3rem !important;
}
.mr-4,
.mx-4 {
    margin-right: 4rem !important;
}


.m-2 {
    margin: 2rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 1rem !important;
}

.mt-05,
.my-05 {
    margin-top: 0.5rem !important;
}

.mt-1,
.my-1 {
    margin-top: 1rem !important;
}

.mt-2,
.my-2 {
    margin-top: 2rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 2rem !important;
}

.mt-3, .my-3 {
    margin-top: 3rem !important;
}

.mb-3, .my-3 {
    margin-bottom: 3rem !important;
}

.mb--3, .my--3 {
    margin-bottom: -3rem !important;
}

.mt-4,
.my-4 {
    margin-top: 4em !important;
}

.mb-4,
.my-4 {
    margin-bottom: 4em !important;
}

.ml-5,
.mx-5 {
    margin-left: 5rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 5rem !important;
}

/* Paddings */
.p-0 {
    padding: 0 !important;
}

.p-05 {
    padding: 0.5rem !important;
}

.p-1 {
    padding: 1rem !important;
}

.pl-1, .px-1 {
    padding-left: 1rem !important;
}

.pr-1, .px-1 {
    padding-right: 1rem !important;
}

.p-2 {
    padding: 2rem !important;
}

.pb-1, .py-1 {
    padding-bottom: 1rem !important;
}

.pt-1, .py-1 {
    padding-top: 1rem !important;
}

.pb-2, .py-2 {
    padding-bottom: 2rem !important;
}

.pt-2, .py-2 {
    padding-top: 2rem !important;
}

/* Flex */
.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-fill {
    flex: 1 0 !important;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-around {
    justify-content: space-around !important;
}

.justify-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: start;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-stretch {
    align-items: stretch;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-center {
    align-self: center;
}

/* Width */
.min-width-25 {
    min-width: 25%;
}
.w-2 {
    width: 2%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100% !important;
}

/*Height*/
.height-100vh {
    height: 100vh;
}

/* Positions */
.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.link-navigation {
    font-weight: bold;
    text-transform: none;
    font-size: 1.2em;
}

.bold {
    font-weight: bold;
}

.MuiTextField-root {
    .MuiInputBase-root {

    }
    .MuiOutlinedInput-inputMarginDense {
    padding-top: 6px;
    padding-bottom: 6px;
        font-size: 1.15em;
    }
}

.total-point .MuiInputBase-root.Mui-disabled {
    background-color: #1c9d40;
    color: #d9d9d6;
    font-weight: bold;
}

.MuiTableCell-root {
    padding: 10px !important;
}