.linkNavigation {
  font-weight: bold;
  text-transform: none;
  font-size: 1.2em;
  margin-right: 1rem;
}

.textTop {
  vertical-align: text-top;
  height: 3.2em;
}
