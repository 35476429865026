.toolbar {
  justify-content: space-between;
  .leftToolbar {
    display: flex;
    align-items: center;
    height: 5rem;
    .logo {
      margin-right: 5rem;
      min-height: 3rem;
      max-height: 4rem;
    }
  }
}
.labelExploitation {
  padding-bottom: 2px;
  font-size: 0.9em;
  font-weight: lighter;
  z-index: 2;
}
.hide {
  display: none;
}
.buttonExploitation {
  font-weight: bold !important;
  padding: 0 0 5px 0 !important;
}
